<template>
  <div v-loading="loading" class="text-black mb-3">
    <div v-if="!showDetail">
      <div class="mb-0 fs-14 fw-700 text-grey-900 flex justify-end">
        <div class="flex items-center">
          <span
            @click="onShowModalUpload(true)"
            class="text-black font-bold cursor-pointer mx-3"
          >
            <svg
              class="mr-1"
              width="16"
              height="16"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.039 5.13579L8.1 3.06528V11.6984C8.1 11.9372 8.19482 12.1662 8.3636 12.335C8.53239 12.5038 8.76131 12.5987 9 12.5987C9.23869 12.5987 9.46761 12.5038 9.6364 12.335C9.80518 12.1662 9.9 11.9372 9.9 11.6984V3.06528L11.961 5.13579C12.0447 5.22017 12.1442 5.28714 12.2539 5.33284C12.3636 5.37855 12.4812 5.40208 12.6 5.40208C12.7188 5.40208 12.8364 5.37855 12.9461 5.33284C13.0558 5.28714 13.1553 5.22017 13.239 5.13579C13.3234 5.05211 13.3903 4.95254 13.436 4.84284C13.4817 4.73314 13.5052 4.61547 13.5052 4.49663C13.5052 4.37779 13.4817 4.26013 13.436 4.15043C13.3903 4.04073 13.3234 3.94116 13.239 3.85747L9.639 0.256576C9.55341 0.174619 9.45248 0.110375 9.342 0.067529C9.12288 -0.0225097 8.87712 -0.0225097 8.658 0.067529C8.54752 0.110375 8.44659 0.174619 8.361 0.256576L4.761 3.85747C4.67709 3.94141 4.61052 4.04106 4.56511 4.15072C4.51969 4.26039 4.49632 4.37793 4.49632 4.49663C4.49632 4.61534 4.51969 4.73288 4.56511 4.84254C4.61052 4.95221 4.67709 5.05186 4.761 5.13579C4.84491 5.21973 4.94454 5.28631 5.05418 5.33173C5.16382 5.37716 5.28133 5.40054 5.4 5.40054C5.51867 5.40054 5.63618 5.37716 5.74582 5.33173C5.85546 5.28631 5.95509 5.21973 6.039 5.13579ZM17.1 8.99776C16.8613 8.99776 16.6324 9.0926 16.4636 9.26142C16.2948 9.43025 16.2 9.65922 16.2 9.89798V15.2993C16.2 15.5381 16.1052 15.7671 15.9364 15.9359C15.7676 16.1047 15.5387 16.1996 15.3 16.1996H2.7C2.4613 16.1996 2.23239 16.1047 2.0636 15.9359C1.89482 15.7671 1.8 15.5381 1.8 15.2993V9.89798C1.8 9.65922 1.70518 9.43025 1.5364 9.26142C1.36761 9.0926 1.13869 8.99776 0.9 8.99776C0.661305 8.99776 0.432387 9.0926 0.263604 9.26142C0.0948211 9.43025 0 9.65922 0 9.89798V15.2993C0 16.0156 0.284464 16.7025 0.790812 17.209C1.29716 17.7155 1.98392 18 2.7 18H15.3C16.0161 18 16.7028 17.7155 17.2092 17.209C17.7155 16.7025 18 16.0156 18 15.2993V9.89798C18 9.65922 17.9052 9.43025 17.7364 9.26142C17.5676 9.0926 17.3387 8.99776 17.1 8.99776Z"
                fill="#03267A"
              />
            </svg>
            Upload Phiếu
          </span>
          <span
            @click="Modalcreate(true)"
            class="fs-14 fw-700 text-grey-900 cursor-pointer"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-1"
            >
              <path
                d="M8.99984 0.666748C7.35166 0.666748 5.7405 1.15549 4.37009 2.07117C2.99968 2.98685 1.93158 4.28834 1.30084 5.81105C0.670115 7.33377 0.505087 9.00933 0.82663 10.6258C1.14817 12.2423 1.94185 13.7272 3.10728 14.8926C4.27272 16.0581 5.75758 16.8517 7.37409 17.1733C8.9906 17.4948 10.6662 17.3298 12.1889 16.6991C13.7116 16.0683 15.0131 15.0002 15.9288 13.6298C16.8444 12.2594 17.3332 10.6483 17.3332 9.00008C17.3332 7.90573 17.1176 6.8221 16.6988 5.81105C16.28 4.80001 15.6662 3.88135 14.8924 3.10752C14.1186 2.3337 13.1999 1.71987 12.1889 1.30109C11.1778 0.882296 10.0942 0.666748 8.99984 0.666748ZM8.99984 15.6667C7.6813 15.6667 6.39237 15.2758 5.29604 14.5432C4.19971 13.8107 3.34523 12.7695 2.84064 11.5513C2.33606 10.3331 2.20404 8.99269 2.46127 7.69948C2.71851 6.40627 3.35345 5.21839 4.2858 4.28604C5.21815 3.35369 6.40603 2.71875 7.69924 2.46151C8.99245 2.20428 10.3329 2.3363 11.5511 2.84088C12.7692 3.34547 13.8104 4.19995 14.543 5.29628C15.2755 6.39261 15.6665 7.68154 15.6665 9.00008C15.6665 10.7682 14.9641 12.4639 13.7139 13.7141C12.4636 14.9644 10.768 15.6667 8.99984 15.6667ZM12.3332 8.16675H9.83317V5.66675C9.83317 5.44573 9.74538 5.23377 9.5891 5.07749C9.43282 4.92121 9.22085 4.83342 8.99984 4.83342C8.77883 4.83342 8.56687 4.92121 8.41059 5.07749C8.25431 5.23377 8.16651 5.44573 8.16651 5.66675V8.16675H5.66651C5.44549 8.16675 5.23353 8.25455 5.07725 8.41083C4.92097 8.56711 4.83317 8.77907 4.83317 9.00008C4.83317 9.2211 4.92097 9.43306 5.07725 9.58934C5.23353 9.74562 5.44549 9.83342 5.66651 9.83342H8.16651V12.3334C8.16651 12.5544 8.25431 12.7664 8.41059 12.9227C8.56687 13.079 8.77883 13.1667 8.99984 13.1667C9.22085 13.1667 9.43282 13.079 9.5891 12.9227C9.74538 12.7664 9.83317 12.5544 9.83317 12.3334V9.83342H12.3332C12.5542 9.83342 12.7662 9.74562 12.9224 9.58934C13.0787 9.43306 13.1665 9.2211 13.1665 9.00008C13.1665 8.77907 13.0787 8.56711 12.9224 8.41083C12.7662 8.25455 12.5542 8.16675 12.3332 8.16675Z"
                fill="#03267A"
              />
            </svg>
            Thêm Phiếu mới
          </span>
        </div>
      </div>

      <div v-if="cares">
        <el-table
          @row-click="goToViewDetail"
          class="cursor-pointer"
          :data="cares"
          :tree-props="{ children: 'emr_cares' }"
          row-key="date"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
          row-class-name="text-black text-sm"
          header-cell-class-name="text-black text-base font-semibold"
          default-expand-all
        >
          <el-table-column label="Ngày">
            <template slot-scope="scope">
              <span v-if="scope.row.id">
                <i class="el-icon-time mr-1"></i>
                <span>{{ formatTime(scope.row) }}</span>
              </span>
              <span v-else>
                <i class="el-icon-date mr-1"></i>
                <span>{{ formatDate(scope.row) }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="user.name" label="Người tạo"></el-table-column>
          <el-table-column prop="clinic_name" label="Khoa"></el-table-column>
          <el-table-column label class-name="text-right" width="100">
            <template slot-scope="scope">
              <div v-if="!scope.row.id" class="inline-block">
                <el-button size="mini" icon="el-icon-printer">
                  {{ $t("multidisciplinary_board.lbl_print") }}
                </el-button>
              </div>
              <img
                src="/images/icon-delete.svg"
                v-else
                alt
                @click="(handleDeleteEMR(scope.row)), (isConflictClick = true)"
              />
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-content-end mt-3">
          <el-pagination
            v-if="cares && carePaging.last_page > 1"
            background
            layout="prev, pager, next"
            :total="carePaging.total"
            :page-size="parseInt(carePaging.per_page)"
            @current-change="handleCurrentPage"
          ></el-pagination>
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" id="modalUploadPhieuChamSoc">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="d-flex justify-content-between p-3">
            <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
              <span>UPLOAD PHIẾU</span>
            </div>
            <!-- <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                class="fs-30 fw-500"
              >&times;</span>
            </button>-->
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-12 mb-3">
                    <div class="form-group">
                      <label class="robo-16-500 required">Tên Phiếu</label>
                      <input
                        type="text"
                        class="form-control mt-2 robo-16-500"
                        placeholder="Nhập tên phiếu"
                        v-model="form.name"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 mb-3">
                    <div class="form-group">
                      <label class="robo-16-500 required">Phiếu ngày</label>
                      <el-date-picker
                        type="datetime"
                        placeholder="Chọn ngày giờ"
                        v-model="form.date"
                      ></el-date-picker>
                    </div>
                  </div>
                  <div
                    class="col-sm-12 d-flex justify-content-between align-items-center flex-wrap"
                  >
                    <p class="mb-0">
                      {{ file ? file.name : "Chưa chọn file..." }}
                    </p>
                    <p
                      v-if="file"
                      style="border-bottom: solid 1.5px #20419b"
                      @click="onShowPickFilePCS()"
                      class="cursor-pointer txt-pri d-flex mb-0"
                    >
                      Thay đổi
                    </p>
                    <button
                      v-if="!file"
                      class="btn radius-0 d-flex align-items-center bg-pri bd-pri text-white robo-16-500 mr-2 text-right"
                      @click="onShowPickFilePCS()"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.039 5.13579L8.1 3.06528V11.6984C8.1 11.9372 8.19482 12.1662 8.3636 12.335C8.53239 12.5038 8.7613 12.5987 9 12.5987C9.2387 12.5987 9.46761 12.5038 9.6364 12.335C9.80518 12.1662 9.9 11.9372 9.9 11.6984V3.06528L11.961 5.13579C12.0447 5.22017 12.1442 5.28714 12.2539 5.33284C12.3636 5.37855 12.4812 5.40208 12.6 5.40208C12.7188 5.40208 12.8364 5.37855 12.9461 5.33284C13.0558 5.28714 13.1553 5.22017 13.239 5.13579C13.3234 5.0521 13.3903 4.95254 13.436 4.84284C13.4817 4.73314 13.5052 4.61547 13.5052 4.49663C13.5052 4.37779 13.4817 4.26013 13.436 4.15043C13.3903 4.04073 13.3234 3.94116 13.239 3.85747L9.639 0.256576C9.55341 0.174619 9.45248 0.110375 9.342 0.067529C9.12288 -0.0225097 8.87712 -0.0225097 8.658 0.067529C8.54752 0.110375 8.44659 0.174619 8.361 0.256576L4.761 3.85747C4.67708 3.94141 4.61052 4.04106 4.56511 4.15072C4.51969 4.26039 4.49632 4.37793 4.49632 4.49663C4.49632 4.61534 4.51969 4.73288 4.56511 4.84254C4.61052 4.95221 4.67708 5.05186 4.761 5.13579C4.84491 5.21973 4.94454 5.28631 5.05418 5.33173C5.16382 5.37716 5.28133 5.40054 5.4 5.40054C5.51867 5.40054 5.63618 5.37716 5.74582 5.33173C5.85546 5.28631 5.95509 5.21973 6.039 5.13579ZM17.1 8.99776C16.8613 8.99776 16.6324 9.0926 16.4636 9.26142C16.2948 9.43025 16.2 9.65922 16.2 9.89798V15.2993C16.2 15.5381 16.1052 15.7671 15.9364 15.9359C15.7676 16.1047 15.5387 16.1996 15.3 16.1996H2.7C2.4613 16.1996 2.23239 16.1047 2.0636 15.9359C1.89482 15.7671 1.8 15.5381 1.8 15.2993V9.89798C1.8 9.65922 1.70518 9.43025 1.5364 9.26142C1.36761 9.0926 1.13869 8.99776 0.9 8.99776C0.661305 8.99776 0.432387 9.0926 0.263604 9.26142C0.0948211 9.43025 0 9.65922 0 9.89798V15.2993C0 16.0156 0.284464 16.7025 0.790812 17.209C1.29716 17.7155 1.98392 18 2.7 18H15.3C16.0161 18 16.7028 17.7155 17.2092 17.209C17.7155 16.7025 18 16.0156 18 15.2993V9.89798C18 9.65922 17.9052 9.43025 17.7364 9.26142C17.5676 9.0926 17.3387 8.99776 17.1 8.99776Z"
                          fill="white"
                        />
                      </svg>
                      Chọn file
                    </button>
                    <input
                      id="file-uploadPCS"
                      class="d-none"
                      name="file-uploadPCS"
                      type="file"
                      @change="onSelectFilePCS"
                      accept="jpeg, .png, .jpg, .gif, .svg, .doc, .docx, .pdf, .xls, .xlsx, .xlsm, .ppt, .pptx, .heic"
                      :key="fileInputKey"
                    />
                  </div>
                  <div class="col-12">
                    <small class="text-red">{{ err_file }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="closeModal"
              type="button"
              class="btn btn-muted"
              data-dismiss="modal"
              @click="closeModal"
            >
              Huỷ
            </button>
            <button
              :disabled="is_loading"
              type="button"
              class="btn bg-pri bd-pri text-white"
              @click="onSubmit"
            >
              Lưu
              <span
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                v-if="is_loading"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modalUpdateFilePCS"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalUpdatePCSLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sx" role="document">
        <div class="modal-content" v-loading="loading">
          <div class="d-flex justify-content-between p-3">
            <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
              <span class="uppercase ml-5">Chi tiết phiếu</span>
            </div>
            <div
              v-if="modeUpdate === null"
              @click="modeUpdate = 1"
              class="mt-2.5 cursor-pointer mr-2"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.75 8.98568C15.5511 8.98568 15.3603 9.06 15.2197 9.19228C15.079 9.32457 15 9.50399 15 9.69107V13.9234C15 14.1105 14.921 14.2899 14.7803 14.4222C14.6397 14.5545 14.4489 14.6288 14.25 14.6288H3.75C3.55109 14.6288 3.36032 14.5545 3.21967 14.4222C3.07902 14.2899 3 14.1105 3 13.9234V4.04798C3 3.8609 3.07902 3.68148 3.21967 3.5492C3.36032 3.41691 3.55109 3.3426 3.75 3.3426H8.25C8.44891 3.3426 8.63968 3.26828 8.78033 3.13599C8.92098 3.00371 9 2.82429 9 2.63721C9 2.45013 8.92098 2.27071 8.78033 2.13843C8.63968 2.00614 8.44891 1.93182 8.25 1.93182H3.75C3.15326 1.93182 2.58097 2.15478 2.15901 2.55163C1.73705 2.94849 1.5 3.48674 1.5 4.04798V13.9234C1.5 14.4846 1.73705 15.0229 2.15901 15.4197C2.58097 15.8166 3.15326 16.0395 3.75 16.0395H14.25C14.8467 16.0395 15.419 15.8166 15.841 15.4197C16.2629 15.0229 16.5 14.4846 16.5 13.9234V9.69107C16.5 9.50399 16.421 9.32457 16.2803 9.19228C16.1397 9.06 15.9489 8.98568 15.75 8.98568ZM4.5 9.52177V12.5126C4.5 12.6997 4.57902 12.8791 4.71967 13.0114C4.86032 13.1437 5.05109 13.218 5.25 13.218H8.43C8.5287 13.2185 8.62655 13.2007 8.71793 13.1656C8.80931 13.1305 8.89242 13.0788 8.9625 13.0134L14.1525 8.12511L16.2825 6.16414C16.3528 6.09856 16.4086 6.02055 16.4467 5.93459C16.4847 5.84863 16.5043 5.75643 16.5043 5.66331C16.5043 5.5702 16.4847 5.478 16.4467 5.39204C16.4086 5.30608 16.3528 5.22807 16.2825 5.16249L13.1025 2.13639C13.0328 2.07027 12.9498 2.01779 12.8584 1.98198C12.767 1.94617 12.669 1.92773 12.57 1.92773C12.471 1.92773 12.373 1.94617 12.2816 1.98198C12.1902 2.01779 12.1072 2.07027 12.0375 2.13639L9.9225 4.13263L4.7175 9.02095C4.64799 9.08686 4.59299 9.16503 4.55567 9.25097C4.51835 9.33692 4.49943 9.42894 4.5 9.52177ZM12.57 3.6318L14.6925 5.62805L13.6275 6.62969L11.505 4.63345L12.57 3.6318ZM6 9.81098L10.4475 5.62805L12.57 7.62429L8.1225 11.8072H6V9.81098Z"
                  fill="black"
                />
              </svg>
            </div>
            <button
              type="button"
              class="close bg-white border-0"
              id="closeModal"
              @click="close(false)"
            >
              <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3 row">
              <div class="col-md-4">
                <p class="mb-1 fs-16 txt-grey-900 fw-700 mt-2">Tên phiếu:</p>
              </div>
              <div class="col-md-8">
                <el-input
                  v-if="modeUpdate"
                  v-model="form.name"
                  class="w-100 col-md-5"
                  placeholder="Nhập Tên"
                ></el-input>
                <div v-else class="fw-500 txt-grey-900 mt-2">
                  {{ form.name }}
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <div class="col-md-4">
                <p class="mb-1 fs-16 txt-grey-900 fw-700 mt-2">
                  Ngày giờ tạo phiếu:
                </p>
              </div>
              <div class="col-md-8">
                <input
                  v-if="modeUpdate"
                  v-model="form.date"
                  type="datetime-local"
                  class="form-control fw-500 txt-grey-900"
                />
                <div v-else class="fw-500 txt-grey-900 mt-2">
                  {{ formatDateTime(form.date) }}
                </div>
              </div>
            </div>
            <div class="flex justify-center" v-if="!modeUpdate">
              <a
                v-if="form.pdf_url"
                href="javascript:;"
                @click="openFile(form && form.pdf_url)"
                class="txt-pri fs-14 text-decoration-underline"
                >Xem Phiếu</a
              >
            </div>
            <div class="mb-3 row">
              <div class="col-md-9">
                <div v-if="modeUpdate">
                  <p class="mt-3 fs-14" v-if="!fileUpdate">
                    {{ getFilename(form.pdf_url) }}
                  </p>
                  <p v-else>{{ getFilename(fileUpdate.name) }}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div v-if="modeUpdate">
                  <div
                    @click="onShowPickFileUpdate"
                    class="mt-3 ml-4 fs-16 text-blue-900 underline cursor-pointer hover:text-blue-500"
                  >
                    Thay đổi
                  </div>
                  <input
                    id="file-updatePCS"
                    class="d-none"
                    name="file-updatePCS"
                    type="file"
                    accept="jpeg, .png, .jpg, .gif, .svg, .doc, .docx, .pdf, .xls, .xlsx, .xlsm, .ppt, .pptx, .heic"
                    @change="onSelectFileUpdate"
                    :key="fileInputKey"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn bg-white text-pri"
              type="button"
              v-if="modeUpdate"
              @click="closeUpdate"
            >
              Hủy
            </button>
            <button
              v-if="modeUpdate"
              @click="onSubmitUpdate"
              type="button"
              class="btn bg-pri text-white"
            >
              Lưu
            </button>
          </div>
        </div>
      </div>
    </div>

    <Detail
      :careId="careId"
      @refresh="getERMCaresByDate"
      :formData="form"
      :isLoading="loading"
    />
    <CreatePCS
      @refresh="getERMCaresByDate"
      :type="'create'"
      @showDetail="goToViewDetail"
    />
    <PdfViewer
      ref="CareFormPdfViewer"
      containerIDProps="ContainerCareFormPDFViewerID"
      elementIDProps="CareFormPDFViewerID"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import CreatePCS from './CreatePCS'
import Detail from './Detail'
import appUtils from '../../../utils/appUtils'
import { ENVELOPE_DOCUMENT_TYPE } from '../../../utils/constants'
import SignatureRequest from '../../../api/request/SignatureRequest'
import PdfViewer from '../../PdfViewer/PdfViewer.vue'
export default {
  name: 'PhieuChamSocList',
  components: { Detail, CreatePCS, PdfViewer },
  data () {
    const initPagination = {
      total: 0,
      count: 0,
      per_page: 5,
      current_page: 1,
      last_page: 1
    }
    return {
      loading: false,
      modeUpdate: null,
      careId: null,
      err_file: '',
      exts_list: [
        'jpeg',
        'png',
        'jpg',
        'gif',
        'svg',
        'doc',
        'docx',
        'pdf',
        'xls',
        'xlsx',
        'xlsm',
        'ppt',
        'pptx',
        'heic'
      ],
      is_loading: false,
      cares: null,
      carePaging: null,
      listStatus: [
        { name: 'Mới' },
        { name: 'Đang xử lý' },
        { name: 'Đã ký' },
        { name: 'Đã thay thế' }
      ],
      form: {
        medical_record_id: null,
        name: 'Phiếu chăm sóc ' + window.moment().format('DD-MM-YYYY'),
        date: window.moment().format('YYYY-MM-DD HH:mm:ss'),
        format: 2,
        pdf: null,
        pdf_url: ''
      },
      fileUpdate: null,
      file: null,
      showDetail: false,
      fileInputKey: 0,
      initPagination,
      pagination: initPagination,
      isConflictClick: false
    }
  },
  watch: {
    '$route.query.id_care' () {
      this.handleDetail()
    },
    showDetail (val) {
      if (!val) this.getERMCaresByDate()
    }
  },
  mounted () {
    this.handleDetail()
    if (!this.showDetail) this.getERMCaresByDate()
    let self = this
    this.$nextTick(function () {
      window.$('#modalUploadPhieuChamSoc').on('hidden.bs.modal', function (e) {
        self.refresh()
      })
      window.$('#modalUpdateFilePCS').on('hidden.bs.modal', function (e) {
        self.getERMCaresByDate()
        self.getERMCareByID()
        self.modeUpdate = null
        self.refresh()
      })
      window.$('#modalUpdateFilePCS').on('hide.bs.modal', function (e) {
        if (self.modeUpdate) {
          if (
            !confirm(
              'Các thay đổi có thể chưa được lưu. Bạn có muốn lưu không?'
            )
          ) {
            return true
          } else self.onSubmitUpdate()
        }
        self.refresh()
      })
    })
  },
  methods: {
    refresh () {
      const fileUpdatePCS = document.getElementById('file-updatePCS')
      const fileUploadPCS = document.getElementById('file-uploadPCS')

      this.file = null

      if (fileUpdatePCS && fileUpdatePCS.value) fileUpdatePCS.value = ''
      if (fileUploadPCS && fileUploadPCS.value) fileUploadPCS.value = ''

      this.fileInputKey++
      this.form = {
        medical_record_id: null,
        name: 'Phiếu chăm sóc ' + window.moment().format('DD-MM-YYYY'),
        date: window.moment().format('YYYY-MM-DD HH:mm:ss'),
        format: 2,
        pdf: null
      }
    },
    Modalcreate (show) {
      window.$('#ModalPCS').modal(show ? 'show' : 'hide')
    },
    //
    onSubmitUpdate () {
      this.form.pdf = this.fileUpdate
      this.updateERMCare(this.form)
      this.modeUpdate = null
      this.refresh()
    },
    closeUpdate () {
      this.$emit('refresh')
      this.modeUpdate = null
      this.refresh()
    },
    close (show) {
      window.$('#modalUpdateFilePCS').modal(show ? 'show' : 'hide')
      this.modeUpdate = null
      this.refresh()
    },

    getFilename (fullPath) {
      if (!_.isString(fullPath)) return
      return fullPath.split('/').pop()
    },
    openFile (url) {
      if (!url) return
      window.open(url)
    },
    async goToViewDetail (row) {
      if (this.isConflictClick) {
        this.isConflictClick = false
        return
      }
      this.loading = true
      const res = await this.handleGetEnvelopeByDocumentID(row)
      if (res) {
        this.$refs.CareFormPdfViewer.handleOpen(
          res && res.document_url,
          ENVELOPE_DOCUMENT_TYPE.CARE_FORM,
          row.id
        )
        this.loading = false

        return
      }

      if (!row.id) {
        const params = {
          from: window.moment(row.date).startOf('day').toDate(),
          to: window.moment(row.date).endOf('day').toDate(),
          sort_by: 'asc',
          sort: 'date'
        }
        this.careId = null
        this.getERMCares(params)

        window.$('#modalViewPCS').modal('show')
        return
      }

      this.careId = row.id
      this.getERMCareByID()

      if (row.pdf_url) {
        window.$('#modalUpdateFilePCS').modal('show')
      } else {
        window.$('#modalViewPCS').modal('show')
      }
    },
    validateFile (file) {
      let ext = file.name.split('.').pop()
      return !!this.exts_list.find((el) => el === ext.toLowerCase())
    },
    formatDateTime (date) {
      return window.moment(date).format('HH:mm DD/MM/YYYY')
    },
    onSubmit () {
      let self = this
      if (self.is_loading) return
      if (!self.file) {
        self.err_file = 'Vui lòng chọn file'
        return false
      } else if (!self.validateFile(self.file)) {
        self.err_file =
          'Vui lòng chọn file có định dạng jpeg,png,jpg,gif,svg,doc,docx,pdf,xls,xlsx,heic'
        return false
      } else {
        this.form.medical_record_id = this.$route.params.id
        this.form.pdf = this.file
        this.form.date = window
          .moment(this.form.date)
          .format('YYYY-MM-DD HH:mm:ss')
        console.log(this.form)
        self.postERMCare(this.form)
        this.closeModal()
        window.$('button#closeModal').trigger('click')
      }
    },
    openSelectFile () {
      this.$refs.selectFileUpdatePCS.click()
      this.$refs.selectFileUpdatePCS.value = null
    },
    onSelectFileUpdatePPTTT (event) {
      this.fileUpdate = event.target.files[0]
      this.$refs.selectFileUpdatePCS.value = null
    },
    onSelectFileUpdate (event) {
      this.fileUpdate = event.target.files[0]
    },
    onShowPickFileUpdate () {
      window.$('input[id="file-updatePCS"]').trigger('click')
    },
    onSelectFilePCS (event) {
      this.file = event.target.files[0]
    },
    onShowPickFilePCS () {
      window.$('input[id="file-uploadPCS"]').trigger('click')
    },
    handleDetail () {
      if (this.$route.query.id_care) {
        this.showDetail = true
      } else {
        this.showDetail = false
      }
    },
    closeModal () {
      this.refresh()
    },
    formatStatus (row) {
      return row.status
        ? this.listStatus[row.status].name
        : this.listStatus[0].name
    },
    formatName (row) {
      return row.name && row.name
    },
    formatFile (row) {
      return row.format === 1 ? 'Dữ liệu' : 'File'
    },
    formatDMY (row) {
      return row.date && appUtils.formatDateTimeFull(row.date)
    },
    formatDate (row) {
      return row.date && appUtils.formatDate(row.date)
    },
    formatTime (row) {
      return row.date && appUtils.formatTime(row.date)
    },
    getUserName (row) {
      return row.user && row.user.name
    },
    goToEdit (id) {
      this.$router.push({
        query: { tab: this.$route.query.tab, id_care: id, type: 'edit' }
      })
    },
    async getERMCareByID () {
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getERMCareByID(this.careId)
        .then((r) => {
          this.form = r.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    setPagination (data) {},
    async getERMCares (params) {
      let id = this.$route.params.id
      if (!id) {
        return
      }
      const paramsData = {
        ...params,
        limit: 50,
        page: this.$route.query.page || 1,
        sort_by: 'asc'
      }
      this.loading = true
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getERMCares(id, paramsData)

      let listCares = res.data?.data
      const indexPdf = listCares.findIndex((x) => x.pdf_url)
      if (indexPdf !== -1) {
        listCares.splice(indexPdf, 1)
      }
      await Promise.all(
        listCares?.map(async (item) => {
          await this.handleGetEnvelopeByDocumentID(item).then((res) => {
            if (res && res.id) {
              item.isSigned = true
              item.issuer_info = res.issuer_info
              item.sign_date = res.created_at
            }
          })
        })
      )

      this.form = listCares
    },
    async getERMCaresByDate () {
      let id = this.$route.params.id
      if (!id) {
        return
      }
      const params = {
        limit: 50,
        page: this.$route.query.page || 1,
        sort: 'date',
        sort_by: 'desc'
      }
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getERMCaresByDate(id, params)
        .then((r) => {
          const dataRaw = r.data

          this.cares = dataRaw?.map((item) => {
            const _emrCares = item?.emr_cares?.sort(
              (a, b) => new Date(b.date) - new Date(a.date)
            )
            const _data = {
              ...item,
              emr_cares: _emrCares
            }

            return _data
          })

          const meta = {
            ...this.pagination,
            total: r.data?.length,
            count:
              r.data?.length > this.pagination.per_page
                ? this.pagination.per_page
                : r.data?.length,
            last_page: Math.ceil(r.data?.length / this.pagination.per_page)
          }

          this.pagination = meta
          this.carePaging = meta
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleCurrentPage (page) {
      let query = Object.assign({}, this.$route.query)
      query.page = page
      this.$router.replace({ query }).catch((_) => {})
      this.getERMCaresByDate()
    },
    onShowModalUpload (show) {
      window.$('#modalUploadPhieuChamSoc').modal(show ? 'show' : 'hide')
    },
    async updateERMCare (event) {
      const formData = new FormData()
      for (const key in event) {
        formData.append(key, event[key] || '')
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .updateERMCare(this.careId, formData)
        .then((r) => {
          this.getERMCareByID()
          this.$toast.open({
            message: 'Cập nhật thành công',
            type: 'success'
          })
        })
    },
    async postERMCare (event) {
      const formData = new FormData()
      for (const key in event) {
        formData.append(key, event[key] || '')
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .postERMCare(formData)
        .then((r) => {
          this.err_file = ''
          if (r) this.getERMCaresByDate()
          this.$toast.open({
            message: 'Upload file thành công',
            type: 'success'
          })
        })
    },
    download (pdf) {
      window.open(pdf)
    },
    async handleGetEnvelopeByDocumentID (item) {
      if (!item?.id) return

      try {
        this.loading = true
        const request = new SignatureRequest()
        const response = await request.getSignEnvelopeByDocumentID({
          doc_id: item.id,
          doc_type: ENVELOPE_DOCUMENT_TYPE.CARE_FORM
        })

        return response.data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleDeleteEMR (item) {
      this.$confirm(
        `Bạn có chắc chắn muốn xoá phiếu chăm sóc ngày ${this.formatDate(
          item
        )} lúc ${this.formatTime(item)} không?`,
        {
          confirmButtonText: 'Xác nhận'
        }
      )
        .then(_ => {
          this.deleteEMR(item)
        })
        .catch(_ => {})
    },
    async deleteEMR (item) {
      try {
        this.loading = true
        await this.$rf
          .getRequest('HIDRequest')
          .deleteEMRCare({
            emr_care_id: item.id,
            who_i_am: this.$user.id
          })
          .then(res => {
            if (res) {
              this.getERMCaresByDate()
              this.$toast.open({
                message: this.$t('Xoá phiếu chăm sóc thành công'),
                type: 'success'
              })
            }
          })
      } catch (error) {
        this.$toast.open({
          message: this.$t('Có lỗi xảy ra, vui lòng thử lại'),
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<template>
  <div
    class="modal fade"
    id="ModalPCS"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalPCSLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" v-loading="is_loading">
        <div class="d-flex justify-content-between p-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100">Phiếu chăm sóc</div>
          <button type="button" @click="closeModal" class="close bg-white border-0">
            <span class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body py-10">
          <div class="row">
            <div class="col-md-6">
              <div class="fs-14 fw-500">Chọn mẫu Phiếu chăm sóc</div>
              <el-select
                clearable
                filterable
                v-model="formCreate.doc_template_id"
                class="w-full"
                placeholder="Chọn mẫu phiếu"
                @change="handleChangeDocTemplate"
                remote
                :remote-method="getDocsTemplateList"
                :loading="is_loading"
              >
                <el-option
                  v-for="item in docsTemplateList"
                  :key="item.id + 'md'"
                  :label="handleGetTemLabel(item)"
                  :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <label class="fw-700">Tên Phiếu</label>
              <input v-model="formCreate.name" class="form--input txt-grey-900 h-10" />
            </div>

            <div class="col-md-3">
              <label class="fw-700">Ngày giờ điều trị</label>
              <input
                type="datetime-local"
                placeholder="Chọn ngày giờ"
                class="form--input h-10"
                v-model="formCreate.date"
              />
            </div>

            <div class="col-md-3">
              <label class="fw-700">Khoa</label>
              <el-input class="form--input h-10 px-0" v-model="formCreate.clinic_name"></el-input>
            </div>
          </div>
          <div class="row mt-3">
            <div>
              <div class="fw-700 mb-2">Xếp loại chăm sóc</div>
              <div class="flex items-center gap-2">
                <div
                  v-for="item in listCareRating"
                  :key="item.value"
                  class="card mb-2"
                  :class="care_rating === item.value ? 'active' : ''  "
                  @click="handleSelect(item)"
                >
                  <span>{{ item.label }}</span>
                </div>
              </div>
              <EditorInput
                :prop_text="formCreate.care_rating"
                :id="'care_rating'"
                @onChangeContent="(t) => { formCreate.care_rating = t }"
              />
              <div class="fw-700 mb-2 mt-6">Diễn biến bệnh</div>
              <EditorInput
                :prop_text="formCreate.disease_progression"
                :id="'disease_progression'"
                @onChangeContent="(t) => { formCreate.disease_progression = t }"
              />
              <label class="fw-700 txt-grey-900 mb-2 mt-6">Thực hiện y lệnh/ chăm sóc</label>

              <EditorInput
                :prop_text="formCreate.execute_assess"
                :id="'execute_assess'"
                @onChangeContent="(t) => { formCreate.execute_assess = t; }"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="onSubmit" type="button" class="btn bg-pri text-white">Lưu</button>
        </div>
      </div>
    </div>
    <Detail :careId="careId" />
  </div>
</template>
<script>
import Detail from './Detail'
import CkeditorNormal from '@/components/Editors/CkeditorNormal.vue'
import moment from 'moment'
import EditorInput from '../../../components/EditorInput.vue'
import { MENU_EMR_TEMPLATE } from '../../../utils/constants'

export default {
  name: 'ModalCreatePCS',
  components: { CkeditorNormal, Detail, EditorInput },
  props: ['type'],
  data () {
    return {
      careId: null,
      is_loading: false,
      file: null,
      formCreate: {
        name: 'Phiếu chăm sóc ' + moment().format('DD-MM-YYYY HH:mm:ss'),
        date: moment().format(`YYYY-MM-DDTHH:mm`),
        format: 1,
        disease_progression: '',
        execute_assess: '',
        clinic_name: this.$globalClinic.name || '',
        doc_template_id: null,
        care_rating: ''
      },
      docsTemplateList: [],
      listCareRating: [
        { label: 'CS Cấp I', value: 1 },
        { label: 'CS Cấp II', value: 2 },
        { label: 'CS Cấp III', value: 3 },
        { label: 'Khác', value: 4 }
      ],
      care_rating: 0
    }
  },
  mounted () {
    let self = this
    self.getDocsTemplateList()
    this.$nextTick(function () {
      window.$('#ModalPCS').on('hidden.bs.modal', function (e) {
        if (self.type === 'create') {
          self.refresh()
        }
      })
    })
  },
  methods: {
    async onSubmit () {
      if (this.type === 'create') {
        this.is_loading = true

        const params = {
          ...this.formCreate,
          medical_record_id: this.$route.params.id
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .postERMCare(params)
          .then(r => {
            this.careId = r.data.id
            window.$('#ModalPCS').modal('hide')
            this.$emit('showDetail', r?.data)
            // window.$('#modalViewPCS').modal('show')
            this.$emit('refresh')
            this.refresh()
            this.$toast.open({
              message: 'Thêm thành công',
              type: 'success'
            })
          })
          .finally(() => {
            this.is_loading = false
          })
      }
    },
    refresh () {
      this.formCreate = {
        name: 'Phiếu chăm sóc ' + moment().format('DD-MM-YYYY HH:mm:ss'),
        date: moment().format(`YYYY-MM-DDTHH:mm`),
        format: 1,
        disease_progression: '',
        execute_assess: '',
        doc_template_id: null,
        care_rating: ''
      }
      this.care_rating = 0
    },
    closeModal () {
      if (this.type === 'create') {
        window.$('#ModalPCS').modal('hide')
      } else {
        //
      }
    },
    async getDocsTemplateList (search) {
      try {
        this.loading = true
        const params = {
          workspace_id: this.$globalClinic.id,
          limit: 1000,
          sort_by: 'desc',
          type: Number(MENU_EMR_TEMPLATE[4].value)
        }
        if (search) {
          params.keyword = search
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .getDocsTemplateList(params)
          .then(r => {
            // this.docsTemplateList = responseData?.data || []
            const responseData = r.data?.data?.data || []
            this.docsTemplateList = responseData.filter(
              item =>
                item?.author_id === this.$user?.id ||
                item?.emr_temp_name?.is_public === 1
            )
          })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleChangeDocTemplate (selectedDoc) {
      const docTemplateInfo =
        this.docsTemplateList.find(item => item.id === selectedDoc) || {}
      const contentObj = docTemplateInfo.content
        ? JSON.parse(docTemplateInfo.content)
        : {}
      this.formCreate = {
        ...this.formCreate,
        name: docTemplateInfo.name,
        disease_progression: contentObj.disease_progression?.value,
        execute_assess: contentObj.execute_assess?.value
      }
    },
    handleGetTemLabel (data) {
      if (data?.emr_temp_name?.name) {
        return `${data.name} - ${data.emr_temp_name.name}`
      }

      return data.name
    },
    handleSelect (item) {
      this.care_rating = item.value
      if (item.value !== 4) this.formCreate.care_rating = item.label
      else {
        this.formCreate.care_rating = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  cursor: pointer;
  padding: 4px 8px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  width: fit-content;
  transition: all linear 0.2s;
}
.active {
  background: #20419b;
  color: #fff;
}
</style>

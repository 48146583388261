<template>
  <div>
    <div
      class="modal fade"
      id="modalViewPCS"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalViewPCSLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content" v-loading="loading">
          <div
            class="sticky top-0 flex justify-content-end bg-white text-base font-semibold z-50 mb-3 mt-2 print:hidden"
          >
            <div v-if="mode === 'update'">
              <button
                type="button"
                @click="closeUpdate"
                class="close bg-white border-0"
              >
                <span class="fs-30 fw-500">&times;</span>
              </button>
            </div>
            <div v-else-if="careId">
              <button
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="openModalSignPDF()"
              >
                <div class="loading">
                  <div v-if="loading" class="spinner-border mr-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="#03267A"
                    d="M9.021 1.772a2.445 2.445 0 0 1 3.458 3.457l-.272.271l.086.086a2 2 0 0 1 0 2.828l-1.44 1.44a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-5.646 5.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l6.625-6.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L3.187 9.02l-.925 2.774l2.982-.745l6.527-6.527a1.445 1.445 0 0 0 0-2.043ZM4.967 15c-1.235 0-2.208-.566-2.855-1.107l1.284-.32a3.2 3.2 0 0 0 1.57.427c.406 0 .824-.164 1.27-.457c.447-.293.878-.687 1.314-1.095l.113-.107c.385-.361.784-.737 1.166-.996c.408-.278.95-.53 1.517-.311c.376.144.62.41.791.736c.161.306.272.688.38 1.105c.05.19.133.374.23.498a.46.46 0 0 0 .114.112c.025.015.036.015.039.015c.122 0 .331-.082.63-.286c.185-.126.344-.252.502-.379c.098-.078.197-.157.302-.235c.279-.209.52-.349.697-.438a3.05 3.05 0 0 1 .278-.124l.02-.008l.007-.002l.003-.001h.001l.16.473l-.159-.474a.5.5 0 0 1 .321.947l-.004.002a2.141 2.141 0 0 0-.175.08c-.13.064-.321.174-.55.346c-.059.043-.135.104-.223.174c-.181.143-.409.324-.618.466c-.31.212-.741.46-1.192.46c-.436 0-.751-.265-.946-.517a2.435 2.435 0 0 1-.405-.858c-.11-.423-.196-.7-.296-.89c-.09-.168-.17-.232-.266-.27c-.082-.03-.245-.033-.595.206c-.313.212-.655.533-1.058.912l-.1.093c-.431.405-.92.855-1.449 1.202c-.53.348-1.14.621-1.818.621Z"
                  />
                </svg>
                {{ $t("multidisciplinary_board.btn_sign") }}
              </button>
              <button
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="showModalSaveDocTemplate(true)"
              >
                <div class="loading">
                  <div v-if="loading" class="spinner-border mr-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>

                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="copy-icon"
                >
                  <path
                    d="M16 20H8C7.20435 20 6.44129 19.6839 5.87868 19.1213C5.31607 18.5587 5 17.7956 5 17V7C5 6.73478 4.89464 6.48043 4.70711 6.29289C4.51957 6.10536 4.26522 6 4 6C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7V17C3 18.3261 3.52678 19.5979 4.46447 20.5355C5.40215 21.4732 6.67392 22 8 22H16C16.2652 22 16.5196 21.8946 16.7071 21.7071C16.8946 21.5196 17 21.2652 17 21C17 20.7348 16.8946 20.4804 16.7071 20.2929C16.5196 20.1054 16.2652 20 16 20ZM10 13C10 13.2652 10.1054 13.5196 10.2929 13.7071C10.4804 13.8946 10.7348 14 11 14H16C16.2652 14 16.5196 13.8946 16.7071 13.7071C16.8946 13.5196 17 13.2652 17 13C17 12.7348 16.8946 12.4804 16.7071 12.2929C16.5196 12.1054 16.2652 12 16 12H11C10.7348 12 10.4804 12.1054 10.2929 12.2929C10.1054 12.4804 10 12.7348 10 13ZM21 8.94C20.9896 8.84813 20.9695 8.75763 20.94 8.67V8.58C20.8919 8.47718 20.8278 8.38267 20.75 8.3L14.75 2.3C14.6673 2.22222 14.5728 2.15808 14.47 2.11C14.4402 2.10576 14.4099 2.10576 14.38 2.11C14.2784 2.05174 14.1662 2.01434 14.05 2H10C9.20435 2 8.44129 2.31607 7.87868 2.87868C7.31607 3.44129 7 4.20435 7 5V15C7 15.7956 7.31607 16.5587 7.87868 17.1213C8.44129 17.6839 9.20435 18 10 18H18C18.7956 18 19.5587 17.6839 20.1213 17.1213C20.6839 16.5587 21 15.7956 21 15V9C21 9 21 9 21 8.94ZM15 5.41L17.59 8H16C15.7348 8 15.4804 7.89464 15.2929 7.70711C15.1054 7.51957 15 7.26522 15 7V5.41ZM19 15C19 15.2652 18.8946 15.5196 18.7071 15.7071C18.5196 15.8946 18.2652 16 18 16H10C9.73478 16 9.48043 15.8946 9.29289 15.7071C9.10536 15.5196 9 15.2652 9 15V5C9 4.73478 9.10536 4.48043 9.29289 4.29289C9.48043 4.10536 9.73478 4 10 4H13V7C13.0027 7.34114 13.0636 7.67933 13.18 8H11C10.7348 8 10.4804 8.10536 10.2929 8.29289C10.1054 8.48043 10 8.73478 10 9C10 9.26522 10.1054 9.51957 10.2929 9.70711C10.4804 9.89464 10.7348 10 11 10H19V15Z"
                    fill="#03267A"
                  />
                </svg>
                {{ $t("multidisciplinary_board.btn_save_tem") }}
              </button>
              <button
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="onPrintNe()"
              >
                <div class="loading">
                  <div v-if="loading" class="spinner-border mr-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 14H17C17.2652 14 17.5196 13.8946 17.7071 13.7071C17.8946 13.5196 18 13.2652 18 13V7C18 6.73478 17.8946 6.48043 17.7071 6.29289C17.5196 6.10536 17.2652 6 17 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H4V9H16V14ZM4 4V0H16V4H17C17.7956 4 18.5587 4.31607 19.1213 4.87868C19.6839 5.44129 20 6.20435 20 7V13C20 13.7956 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7956 16 17 16H16V20H4V16H3C2.20435 16 1.44129 15.6839 0.87868 15.1213C0.31607 14.5587 0 13.7956 0 13L0 7C0 6.20435 0.31607 5.44129 0.87868 4.87868C1.44129 4.31607 2.20435 4 3 4H4ZM6 18H14V11H6V18ZM6 4H14V2H6V4Z"
                    fill="#03267A"
                  />
                </svg>
                {{ $t("multidisciplinary_board.lbl_print") }}
              </button>
              <button
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="modalUpdate(true)"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.75 8.99995C15.5511 8.99995 15.3603 9.07897 15.2197 9.21962C15.079 9.36028 15 9.55104 15 9.74995V14.25C15 14.4489 14.921 14.6396 14.7803 14.7803C14.6397 14.9209 14.4489 15 14.25 15H3.75C3.55109 15 3.36032 14.9209 3.21967 14.7803C3.07902 14.6396 3 14.4489 3 14.25V3.74995C3 3.55104 3.07902 3.36028 3.21967 3.21962C3.36032 3.07897 3.55109 2.99995 3.75 2.99995H8.25C8.44891 2.99995 8.63968 2.92094 8.78033 2.78028C8.92098 2.63963 9 2.44887 9 2.24995C9 2.05104 8.92098 1.86028 8.78033 1.71962C8.63968 1.57897 8.44891 1.49995 8.25 1.49995H3.75C3.15326 1.49995 2.58097 1.73701 2.15901 2.15896C1.73705 2.58092 1.5 3.15322 1.5 3.74995V14.25C1.5 14.8467 1.73705 15.419 2.15901 15.8409C2.58097 16.2629 3.15326 16.5 3.75 16.5H14.25C14.8467 16.5 15.419 16.2629 15.841 15.8409C16.2629 15.419 16.5 14.8467 16.5 14.25V9.74995C16.5 9.55104 16.421 9.36028 16.2803 9.21962C16.1397 9.07897 15.9489 8.99995 15.75 8.99995ZM4.5 9.56995V12.75C4.5 12.9489 4.57902 13.1396 4.71967 13.2803C4.86032 13.4209 5.05109 13.5 5.25 13.5H8.43C8.5287 13.5005 8.62655 13.4816 8.71793 13.4443C8.80931 13.407 8.89242 13.352 8.9625 13.2825L14.1525 8.08495L16.2825 5.99995C16.3528 5.93023 16.4086 5.84728 16.4467 5.75589C16.4847 5.66449 16.5043 5.56646 16.5043 5.46745C16.5043 5.36845 16.4847 5.27042 16.4467 5.17902C16.4086 5.08763 16.3528 5.00468 16.2825 4.93495L13.1025 1.71745C13.0328 1.64716 12.9498 1.59136 12.8584 1.55329C12.767 1.51521 12.669 1.49561 12.57 1.49561C12.471 1.49561 12.373 1.51521 12.2816 1.55329C12.1902 1.59136 12.1072 1.64716 12.0375 1.71745L9.9225 3.83995L4.7175 9.03745C4.64799 9.10753 4.593 9.19065 4.55567 9.28202C4.51835 9.3734 4.49943 9.47125 4.5 9.56995V9.56995ZM12.57 3.30745L14.6925 5.42995L13.6275 6.49495L11.505 4.37245L12.57 3.30745ZM6 9.87745L10.4475 5.42995L12.57 7.55245L8.1225 12H6V9.87745Z"
                    fill="#03267A"
                  />
                </svg>
                {{ $t("multidisciplinary_board.lbl_modify_short") }}
              </button>
              <button
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="handleDelete"
              >
                <img src="/images/icon-delete.svg" alt />
                <span class="ml-1">Xoá</span>
              </button>
              <button
                type="button"
                id="closeModal"
                class="close bg-white border-0 mt-3 mr-3"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
              </button>
            </div>
            <div v-else>
              <button
                type="button"
                id="closeModal"
                class="close bg-white border-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
              </button>
            </div>
          </div>
          <div v-if="mode === 'update'" class="font-bold text-xl text-center">
            Phiếu chăm sóc
          </div>
          <div class="modal-body">
            <div v-if="mode === 'update'">
              <div class="row">
                <div class="col-md-6">
                  <div class="fs-14 fw-500">Chọn mẫu Phiếu chăm sóc</div>
                  <el-select
                    clearable
                    filterable
                    v-model="form.doc_template_id"
                    class="w-full"
                    placeholder="Chọn mẫu phiếu"
                    @change="handleChangeDocTemplate"
                    remote
                    :remote-method="getDocsTemplateList"
                    :loading="loading"
                  >
                    <el-option
                      v-for="item in docsTemplateList"
                      :key="item.id + 'md'"
                      :label="handleGetTemLabel(item)"
                      :value="item.id"
                    />
                  </el-select>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label class="fw-700 mr-2">Tên Phiếu</label>
                  <input v-model="form.name" class="form--input h-10" />
                </div>

                <div class="col-md-3">
                  <label class="fw-700 mr-2">Ngày giờ điều trị</label>
                  <input
                    type="datetime-local"
                    placeholder="Chọn ngày giờ"
                    class="form--input h-10"
                    v-model="form.date"
                  />
                </div>

                <div class="col-md-3">
                  <label class="fw-700">Khoa</label>
                  <el-input
                    class="form--input h-10 px-0"
                    v-model="form.clinic_name"
                  ></el-input>
                </div>
              </div>
              <div class="row mt-3">
                <div>
                  <div class="fw-700 mb-2">Xếp loại chăm sóc</div>
                  <div class="flex items-center gap-2">
                    <div
                      v-for="item in listCareRating"
                      :key="item.value"
                      class="card mb-2"
                      :class="care_rating === item.value ? 'active' : ''"
                      @click="handleSelect(item)"
                    >
                      <span>{{ item.label }}</span>
                    </div>
                  </div>
                  <EditorInput
                    :prop_text="form.care_rating"
                    :id="'care_rating'"
                    @onChangeContent="
                      (t) => {
                        form.care_rating = t;
                      }
                    "
                  />
                  <div class="fw-700 mb-2 mt-6">Diễn biến bệnh</div>

                  <EditorInput
                    :prop_text="form.disease_progression"
                    :id="'disease_progression_update'"
                    @onChangeContent="
                      (t) => {
                        form.disease_progression = t;
                      }
                    "
                  />

                  <label class="fw-700 txt-grey-900 mb-2 mt-6">
                    Thực hiện y lệnh/ chăm sóc và đánh giá tình trạng bệnh
                  </label>

                  <EditorInput
                    :prop_text="form.execute_assess"
                    :id="'execute_assess_update'"
                    @onChangeContent="
                      (t) => {
                        form.execute_assess = t;
                      }
                    "
                  />
                </div>
              </div>
            </div>
            <div v-if="!mode && !careId">
              <div class="flex items-center justify-center mb-3">
                <div class="mx-2 font-bold">Chọn thời gian in</div>
                <div class="w-96">
                  <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                  ></el-date-picker>
                </div>
                <button
                  class="btn bg-pri bd-pri text-white px-4 mx-2"
                  @click="onSubmitDateRange"
                >
                  Áp dụng bộ lọc
                </button>
                <button
                  :disabled="loading"
                  class="btn bg-pri bd-pri text-white px-4 mx-2"
                  @click="onPrintNe()"
                >
                  <span v-if="loading" class="loading mr-2">
                    <span class="spinner-border w-3 h-3" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </span>
                  </span>
                  <span>{{ $t("multidisciplinary_board.lbl_print") }}</span>
                </button>
              </div>
            </div>
            <div v-if="!mode" ref="documentPrint">
              <div id="PhieuChamSocViewDetail" class="mx-auto py-9 hodo-print">
                <div class="pt-12">
                  <div class="flex font">
                    <div class="fs-17 uppercase">
                      <div>
                        {{
                          $globalClinic && $globalClinic.organization
                            ? $globalClinic.organization.authority_in_charge
                            : ""
                        }}
                      </div>
                      <div>
                        {{
                          $globalClinic && $globalClinic.organization
                            ? $globalClinic.organization.name
                            : ""
                        }}
                      </div>
                      <div>{{ $globalClinic && $globalClinic.name }}</div>
                    </div>

                    <div class="col-3 text-right ml-auto">
                      <div class="fs-17 inline-block text-left">
                        <div>
                          MS:
                          <span class="font-bold">09/BV-01</span>
                        </div>
                        <div>
                          Số vào viện: {{ person.ref_id }}
                          <span class="font-bold"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center mb-6">
                    <div class="flex flex-col justify-center items-center">
                      <div class="fs-24 font-bold">PHIẾU CHĂM SÓC</div>
                      <span class="fs-17">Số: .......</span>
                    </div>
                  </div>
                  <div class="block mt-4 fs-18">
                    <div class="col-6">
                      <div class="flex">
                        <div class="fs-18">Họ tên người bệnh:</div>
                        <div class="fs-18 font-bold ml-1">
                          {{ person.name }}
                        </div>
                      </div>
                      <div class="fs-18">
                        Khoa: {{ $globalClinic ? $globalClinic.name : "" }}
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="flex">
                        <div class="fs-18">
                          Tuổi:
                          <span class="fs-18 font-bold ml-1">{{
                            getAge()
                          }}</span>
                        </div>
                      </div>
                      <div class="flex">
                        <div class="fs-18">Buồng:</div>
                        <div class="fs-18 ml-1" v-show="person.admission_room">
                          {{ person.admission_room }}
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="flex">
                        <div class="fs-18">Giới tính:</div>
                        <div class="fs-18 font-bold ml-1">
                          {{ getGender() }}
                        </div>
                      </div>
                      <div class="flex">
                        <div class="fs-18">Giường:</div>
                        <div class="fs-18 ml-1" v-show="person.admission_bed">
                          {{ person.admission_bed }}
                        </div>
                      </div>
                    </div>
                    <div class="fs-18">Chẩn đoán: {{ getTreatDeptMain() }}</div>
                  </div>

                  <div
                    class="mt-4 table-width-full border-table"
                    v-if="dataView.length"
                  >
                    <el-table
                      :data="dataView"
                      :span-method="arraySpanMethod"
                      :default-sort="{ prop: 'date', order: 'descending' }"
                      row-class-name="text-black fs-18"
                      header-cell-class-name="text-black fs-16 font-bold text-center px-0 py-1 align-middle leading-none"
                      border
                    >
                      <el-table-column
                        label="NGÀY"
                        class-name="p-0 text-center align-top"
                        width="70px"
                      >
                        <template slot-scope="scope">
                          <div class="fs-18">
                            {{
                              scope.row.date &&
                              formatDate(scope.row.date, "DD/MM")
                            }}
                          </div>
                          <div class="fs-18">
                            {{
                              scope.row.date &&
                              formatDate(scope.row.date, "YYYY")
                            }}
                          </div>
                          <div
                            class="fs-18 mt-6"
                            v-html="scope.row.care_rating"
                          ></div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Giờ"
                        width="60px"
                        class-name="p-0 text-center align-top uppercase"
                      >
                        <template slot-scope="scope">
                          <div class="fs-18">
                            {{ scope.row.date && formatHours(scope.row.date) }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="THEO DÕI DIỄN BIẾN"
                        class-name="p-0 align-top"
                        width="240px"
                      >
                        <template slot-scope="scope">
                          <div v-if="scope.row.pdf_url" class="text-center">
                            <a
                              :href="
                                scope.row.pdf_url ? scope.row.pdf_url : '#'
                              "
                              target="_blank"
                              >Xem phiếu</a
                            >
                          </div>
                          <div
                            v-else
                            class="fs-18 px-1"
                            v-html="scope.row.disease_progression"
                          ></div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="THỰC HIỆN Y LỆNH/CHĂM SÓC"
                        class-name="p-0 align-top"
                        width="240px"
                      >
                        <template slot-scope="scope">
                          <div
                            class="fs-18 px-1"
                            v-html="scope.row.execute_assess"
                          ></div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="KÝ TÊN"
                        class-name="p-0 align-top"
                        align="center"
                        width="100px"
                      >
                        <template slot-scope="scope">
                          <div class="fs-14 px-1" v-if="scope.row.isSigned">
                            {{
                              scope.row.sign_date &&
                              formatDate(
                                scope.row.sign_date,
                                "HH:mm:ss DD/MM/YYYY"
                              )
                            }}
                            Đã ký
                          </div>
                          <div class="fs-16 font-bold px-1 mt-3">
                            {{
                              scope.row.issuer_info &&
                              scope.row.issuer_info.name
                            }}
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!mode" v-show="false" ref="documentPrintSign">
              <div id="PhieuChamSocViewDetail" class="mx-auto py-9 hodo-print">
                <div class="pt-12">
                  <div class="flex font">
                    <div class="fs-17 uppercase">
                      <div>
                        {{
                          $globalClinic && $globalClinic.organization
                            ? $globalClinic.organization.authority_in_charge
                            : ""
                        }}
                      </div>
                      <div>
                        {{
                          $globalClinic && $globalClinic.organization
                            ? $globalClinic.organization.name
                            : ""
                        }}
                      </div>
                      <div>{{ $globalClinic && $globalClinic.name }}</div>
                    </div>

                    <div class="col-3 text-right ml-auto">
                      <div class="fs-17 inline-block text-left">
                        <div>
                          MS:
                          <span class="font-bold">09/BV-01</span>
                        </div>
                        <div>
                          Số vào viện: {{ person.ref_id }}
                          <span class="font-bold"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center mb-6">
                    <div class="flex flex-col justify-center items-center">
                      <div class="fs-24 font-bold">PHIẾU CHĂM SÓC</div>
                      <span class="fs-17">Số: .......</span>
                    </div>
                  </div>
                  <div class="block mt-4 fs-18">
                    <div class="col-6">
                      <div class="flex">
                        <div class="fs-18">Họ tên người bệnh:</div>
                        <div class="fs-18 font-bold ml-1">
                          {{ person.name }}
                        </div>
                      </div>
                      <div class="fs-18">
                        Khoa: {{ $globalClinic ? $globalClinic.name : "" }}
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="flex">
                        <div class="fs-18">
                          Tuổi:
                          <span class="fs-18 font-bold ml-1">{{
                            getAge()
                          }}</span>
                        </div>
                      </div>
                      <div class="flex">
                        <div class="fs-18">Buồng:</div>
                        <div class="fs-18 ml-1" v-show="person.admission_room">
                          {{ person.admission_room }}
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="flex">
                        <div class="fs-18">Giới tính:</div>
                        <div class="fs-18 font-bold ml-1">
                          {{ getGender() }}
                        </div>
                      </div>
                      <div class="flex">
                        <div class="fs-18">Giường:</div>
                        <div class="fs-18 ml-1" v-show="person.admission_bed">
                          {{ person.admission_bed }}
                        </div>
                      </div>
                    </div>
                    <div class="fs-18">Chẩn đoán: {{ getTreatDeptMain() }}</div>
                  </div>

                  <div
                    class="mt-4 table-width-full border-table"
                    v-if="dataView.length"
                  >
                    <el-table
                      :data="dataView"
                      :span-method="arraySpanMethod"
                      :default-sort="{ prop: 'date', order: 'descending' }"
                      row-class-name="text-black fs-18"
                      header-cell-class-name="text-black fs-16 font-bold text-center px-0 py-1 align-middle leading-none"
                      border
                    >
                      <el-table-column
                        label="NGÀY"
                        class-name="p-0 text-center align-top"
                        width="70px"
                      >
                        <template slot-scope="scope">
                          <div class="fs-18">
                            {{
                              scope.row.date &&
                              formatDate(scope.row.date, "DD/MM")
                            }}
                          </div>
                          <div class="fs-18">
                            {{
                              scope.row.date &&
                              formatDate(scope.row.date, "YYYY")
                            }}
                          </div>
                          <div
                            class="fs-18 mt-6"
                            v-html="scope.row.care_rating"
                          ></div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Giờ"
                        width="60px"
                        class-name="p-0 text-center align-top uppercase"
                      >
                        <template slot-scope="scope">
                          <div class="fs-18">
                            {{ scope.row.date && formatHours(scope.row.date) }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="THEO DÕI DIỄN BIẾN"
                        class-name="p-0 align-top"
                        width="250px"
                      >
                        <template slot-scope="scope">
                          <div v-if="scope.row.pdf_url" class="text-center">
                            <a
                              :href="
                                scope.row.pdf_url ? scope.row.pdf_url : '#'
                              "
                              target="_blank"
                              >Xem phiếu</a
                            >
                          </div>
                          <div
                            v-else
                            class="fs-18 px-1"
                            v-html="scope.row.disease_progression"
                          ></div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="THỰC HIỆN Y LỆNH/CHĂM SÓC"
                        class-name="p-0 align-top"
                        width="250px"
                      >
                        <template slot-scope="scope">
                          <div
                            class="fs-18 px-1"
                            v-html="scope.row.execute_assess"
                          ></div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="KÝ TÊN"
                        class-name="p-0 align-top"
                        width="100px"
                      >
                        <template slot-scope="scope">
                          <div class="font-bold fs-18 text-center">
                            <br />
                            <br />
                            <span v-show="false">
                              {{ scope.row.user && scope.row.user.name }}
                            </span>
                            {{ $user && $user.name }}
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="mode === 'update'">
            <button
              @click="onSubmit"
              type="button"
              class="btn bg-pri text-white"
            >
              Lưu
            </button>
          </div>
        </div>
      </div>
      <ModalSaveDocumentTemplate
        ref="ModalSaveDocumentTemplate"
        :doc-content="docContent"
        :doc-type="MENU_EMR_TEMPLATE[4].value"
      />
    </div>
    <ModalSignPDF
      containerIDProps="CareContainerPDF"
      elementIDProps="CareSignerPDF"
      imageIDProps="CareImageID"
      ref="CareSignerPDF"
      :envelopeName="envelopeName"
      @onSuccess="handleSignDocumentSuccess"
    />
  </div>
</template>

<script>
import moment from 'moment'
import CkeditorNormal from '@/components/Editors/CkeditorNormal.vue'
import appUtils from '../../../utils/appUtils'
import { mapState } from 'vuex'
import html2pdf from 'html2pdf.js'
import EditorInput from '../../../components/EditorInput.vue'
import {
  ENVELOPE_DOCUMENT_TYPE,
  ENVELOPE_NAME_TYPE,
  MENU_EMR_TEMPLATE
} from '../../../utils/constants'
import ModalSaveDocumentTemplate from '../ModalSaveDocumentTemplate.vue'
import { sortBy } from 'lodash'
import SignatureRequest from '../../../api/request/SignatureRequest'
import ModalSignPDF from '../../Signature/ModalSignPDF.vue'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'

export default {
  name: 'PhieuChamSocDetail',
  mixins: [mixinSignPdf],
  components: {
    CkeditorNormal,
    EditorInput,
    ModalSaveDocumentTemplate,
    ModalSignPDF
  },
  props: ['careId', 'formData', 'isLoading'],
  data () {
    const defaultForm = {
      name: '',
      date: null,
      format: 1,
      disease_progression: '',
      execute_assess: '',
      doc_template_id: null,
      care_rating: ''
    }

    return {
      mode: null,
      defaultForm,
      form: defaultForm,
      loading: this.isLoading || false,
      type: null,
      dateRange: [],
      dataView: [],
      docsTemplateList: [],
      MENU_EMR_TEMPLATE,
      isPrint: false,
      listCareRating: [
        { label: 'CS Cấp I', value: 1 },
        { label: 'CS Cấp II', value: 2 },
        { label: 'CS Cấp III', value: 3 },
        { label: 'Khác', value: 4 }
      ],
      care_rating: 0
    }
  },
  computed: {
    ...mapState({
      person: (state) => state.person,
      medicalRecordMain: (state) => state.medicalRecordMain,
      emrData: (state) => state.emrData
    }),
    envelopeName () {
      return this.handleGetEnvelopeName(ENVELOPE_NAME_TYPE.emr, this.emrData)
    },
    docContent () {
      return {
        care_rating: {
          label: 'Xếp loại chăm sóc',
          editor: 1,
          value: this.form?.care_rating
        },
        disease_progression: {
          label: 'Diễn biến bệnh',
          editor: 1,
          value: this.form?.disease_progression
        },
        execute_assess: {
          label: 'Thực hiện y lệnh/chăm sóc',
          editor: 1,
          value: this.form?.execute_assess
        }
      }
    }
  },
  mounted () {
    let self = this

    if (self.formData) {
      const data = self.formData
      if (self.careId) {
        self.form = {
          ...self.form,
          ...data
        }
        self.dataView = [data]
      } else {
        self.dataView = data
        self.dateRange = [data[0]?.date, data[0]?.date]
      }
    }
    self.$nextTick(function () {
      window.$('#modalViewPCS').on('hidden.bs.modal', function (e) {
        self.dateRange = []
        self.mode = null
      })
    })
    this.getDocsTemplateList()
  },
  watch: {
    isLoading (status) {
      this.loading = status
    },
    formData (data) {
      if (data) {
        if (this.careId) {
          this.form = {
            ...this.form,
            ...data
          }
          this.dataView = [data]
        } else {
          this.dataView = data
          this.dateRange = [data[0]?.date, data[0]?.date]
        }
      } else {
        this.form = this.defaultForm
        this.dataView = []
      }
    },
    careId (id) {
      const data = this.formData
      if (id) {
        this.form = {
          ...this.form,
          ...data
        }
        this.dataView = [data]
        this.care_rating = 0
      } else {
        this.dataView = data
        this.dateRange = [data[0]?.date, data[0]?.date]
      }
    }
  },
  methods: {
    async onPrintNe (isOpen = true, ref = 'documentPrint') {
      const self = this
      const bodyHTML = self.$refs[ref].innerHTML
      const tempHTML = appUtils.templateHTMLPrint({ bodyHTML })
      const base64Data = appUtils.base64.decode(tempHTML)
      const url = await this.savePdfToS3(base64Data, isOpen)
      return url
    },
    async savePdfToS3 (base64, isOpen) {
      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName: 'PhieuChamSoc',
          path: 'consultations',
          type: 'test',
          type_id: 'test',
          uuid: 'test'
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data && isOpen) {
          window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }
        this.loading = false
        return res.data?.documentLink
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async getERMCareByID () {
      let id = this.careId
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getERMCareByID(id)
        .then((r) => {
          this.form = r.data
          this.dataView = [r.data]
        })
        .finally(() => {
          this.loading = false
        })
    },
    async onSubmit () {
      let id = this.form.id
      this.loading = true
      const params = {
        ...this.form,
        medical_record_id: this.$route.params.id
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .updateERMCare(id, params)
        .then((r) => {
          this.mode = null
          this.getERMCareByID()
          this.$emit('refresh')
          this.$toast.open({
            message: 'Cập nhật thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSubmitDateRange () {
      const params = {}
      if (this.dateRange && this.dateRange.length > 1) {
        params.from = moment(this.dateRange[0]).startOf('day').toDate()
        params.to = moment(this.dateRange[1]).endOf('day').toDate()
        this.getERMCares(params)
      } else this.getERMCareByID()
    },
    async getERMCares (params) {
      let id = this.$route.params.id
      if (!id) {
        return
      }
      this.loading = true
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getERMCares(id, { ...params, sort_by: 'desc' })

      let _data = res.data?.data
      const indexPdf = _data.findIndex((x) => x.pdf_url)
      if (indexPdf !== -1) {
        _data.splice(indexPdf, 1)
      }
      sortBy(_data, ['date'])
      _data.sort(function compare (a, b) {
        var dateA = new Date(a?.date)
        var dateB = new Date(b?.date)
        return dateA - dateB
      })
      console.log(_data)
      await Promise.all(
        _data?.map(async (item) => {
          await this.handleGetEnvelopeByDocumentID(item).then((res) => {
            if (res && res.id) {
              item.isSigned = true
              item.issuer_info = res.issuer_info
              item.sign_date = res.created_at
            }
          })
        })
      )
      this.dataView = _data
    },
    async handleGetEnvelopeByDocumentID (item) {
      if (!item?.id) return

      try {
        this.loading = true
        const request = new SignatureRequest()
        const response = await request.getSignEnvelopeByDocumentID({
          doc_id: item.id,
          doc_type: ENVELOPE_DOCUMENT_TYPE.CARE_FORM
        })

        return response.data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    closeUpdate () {
      this.mode = null
      self.care_rating = 0
    },
    modalUpdate () {
      this.getERMCareByID()
      this.mode = 'update'
    },
    getGender () {
      return appUtils.getGender(this.person.gender)
    },
    formatDate (date, format) {
      if (!date) return ''
      return window.moment(date).format(format)
    },
    formatHours (date) {
      if (!date) return ''
      return window.moment(date).format('HH:mm')
    },
    getAge () {
      return (
        this.person.birthday &&
        (window.moment().diff(this.person.birthday, 'years', false)
          ? window.moment().diff(this.person.birthday, 'years', false)
          : '')
      )
    },
    getTreatDeptMain () {
      let result = ''
      if (
        this.medicalRecordMain &&
        this.medicalRecordMain.treat_dept_main_code
      ) {
        result += this.medicalRecordMain.treat_dept_main_code
      }
      if (
        this.medicalRecordMain &&
        this.medicalRecordMain.treat_dept_main_name
      ) {
        result = result + '-' + this.medicalRecordMain.treat_dept_main_name
      }
      return result
    },
    exportPdf () {
      this.loading = true
      setTimeout(async () => {
        let options = {
          margin: [7, 11, 7, 7],
          filename: `Phieu_Cham_Soc_${this.form.name}_Ngay_${
            this.form.date || this.dataView[0]?.date || ''
          }.pdf`,
          html2canvas: {
            dpi: 1000,
            scale: 6,
            image: { type: 'png', quality: 1 },
            letterRendering: true,
            allowTaint: true,
            logging: true,
            useCORS: true
          },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        }
        await html2pdf().from(this.$refs.viewDetail).set(options).save()
        this.loading = false
      }, 10)
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (row?.pdf_url) {
        if (columnIndex === 2) {
          return [1, 2]
        } else if (columnIndex === 3) {
          return [0, 0]
        }
      }
    },
    async getDocsTemplateList (search) {
      try {
        this.loading = true
        const params = {
          workspace_id: this.$globalClinic.id,
          limit: 1000,
          sort_by: 'desc',
          type: Number(MENU_EMR_TEMPLATE[4].value)
        }
        if (search) {
          params.keyword = search
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .getDocsTemplateList(params)
          .then((r) => {
            const responseData = r.data?.data?.data || []
            this.docsTemplateList = responseData.filter(
              (item) =>
                item?.author_id === this.$user?.id ||
                item?.emr_temp_name?.is_public === 1
            )
          })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleChangeDocTemplate (selectedDoc) {
      const docTemplateInfo =
        this.docsTemplateList.find((item) => item.id === selectedDoc) || {}
      const contentObj = docTemplateInfo.content
        ? JSON.parse(docTemplateInfo.content)
        : {}
      this.form = {
        ...this.form,
        name: docTemplateInfo.name,
        disease_progression: contentObj.disease_progression?.value,
        execute_assess: contentObj.execute_assess?.value
      }
    },
    handleGetTemLabel (data) {
      if (data?.emr_temp_name?.name) {
        return `${data.name} - ${data.emr_temp_name.name}`
      }

      return data.name
    },
    showModalSaveDocTemplate (show) {
      this.$refs.ModalSaveDocumentTemplate.showModalSaveDocTemplate(show)

      // window.$('#modalSaveDocTemplate-5').modal(show ? 'show' : 'hide')
    },

    async openModalSignPDF () {
      const documentLink = await this.onPrintNe(false, 'documentPrintSign')

      if (!documentLink) {
        this.$toast.open({
          message: 'Không tìm thấy tài liệu cần ký',
          type: 'error'
        })

        return
      }

      const procedureResponse = await this.getSignProcedure()
      const procedureSelected = procedureResponse?.data?.[0] || {}

      const signatories = procedureSelected?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map((item) => {
          let user
          if (item?.user_role === 'care_doctor') {
            user = {
              id: this.$user?.id,
              name: this.$user.name,
              email: this.$user.email
            }
          }

          return {
            ...item,
            user,
            user_id: user?.id
          }
        })

        return arr.concat(signs)
      }, [])

      const signerInfo = signatories?.find(
        (item) => item?.user_id === this.$user?.id
      )
      if (!signerInfo) {
        this.$toast.open({
          type: 'error',
          message: 'Bạn không có quyền ký tài liệu này'
        })

        return
      }

      this.$refs.CareSignerPDF.handleOpen(
        documentLink,
        ENVELOPE_DOCUMENT_TYPE.CARE_FORM,
        Number(this.careId),
        signerInfo?.user_id,
        signatories,
        null,
        procedureSelected?.id
      )

      this.isPrint = false
    },
    async getSignProcedure () {
      try {
        const params = {
          page_num: 1,
          page_size: 1000,
          order: 'desc',
          sort_by: 'id',
          document_type: ENVELOPE_DOCUMENT_TYPE.CARE_FORM,
          ws_id: this.$globalClinic?.id
        }

        const request = new SignatureRequest()

        const response = await request.getSignProcedures(params)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async handleSignDocumentSuccess (dataBase64, responseEnvelope) {
      try {
        this.loading = true
        const file = appUtils.convertBase64tToFile(
          dataBase64,
          parseInt(Date.now() / 1000)
        )
        const url = await this.uploadFiles(file, Number(this.careId))
        await this.handleUpdateEnvelopeDocumentURL(responseEnvelope, url)
        window.open(url, '_blank')
        // await this.handleSignature()

        window.$('#modalViewPCS').modal('hide')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async uploadFiles (file, id) {
      const params = {
        file,
        FolderTarget: `consultation`,
        IdTarget: id
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    },
    async handleUpdateEnvelopeDocumentURL (responseEnvelope, url) {
      if (!responseEnvelope?.id) return

      try {
        const request = new SignatureRequest()

        const response = await request.updateEnvelope(responseEnvelope.id, {
          document_url: url
        })

        if (response.status === 200) {
          this.$toast.open({
            message: 'Ký thành công',
            type: 'success'
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleSelect (item) {
      this.care_rating = item.value
      if (item.value !== 4) this.form.care_rating = item.label
      else {
        this.form.care_rating = ''
      }
    },
    handleDelete () {
      this.$confirm(`Bạn có chắc chắn muốn xoá phiếu chăm sóc này không?`, {
        confirmButtonText: 'Xác nhận'
      })
        .then((_) => {
          this.deleteEMR(this.careId)
        })
        .catch((_) => {})
    },
    async deleteEMR () {
      try {
        this.loading = true
        await this.$rf
          .getRequest('HIDRequest')
          .deleteEMRCare({
            emr_care_id: this.careId,
            who_i_am: this.$user.id
          })
          .then((res) => {
            if (res) {
              this.$emit('refresh')
              window.$('#modalViewPCS').modal('hide')
              this.$toast.open({
                message: this.$t('Xoá phiếu chăm sóc thành công'),
                type: 'success'
              })
            }
          })
      } catch (error) {
        this.$toast.open({
          message: this.$t('Có lỗi xảy ra, vui lòng thử lại'),
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table--border,
  .el-table--group {
    border: 1px solid #000;
  }

  .el-table--border th.el-table__cell,
  .el-table__fixed-right-patch {
    border-bottom: 1px solid #000 !important;
  }

  .el-table__header {
    width: 100%;
  }

  .el-table--border .el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-right: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
  }

  .el-table--border th.el-table__cell > div.cell {
    line-height: 1.25 !important;
  }

  // div {
  //   word-break: inherit !important;
  //   white-space: pre;
  // }
  div.cell {
    padding: 0 4px !important;

    a {
      color: #20409b !important;
    }
  }

  thead th.padding-header {
    padding: 0 8px !important;
  }

  thead th.header-text-left {
    text-align: left !important;
    padding: 0 8px !important;
  }

  .break-words {
    overflow-wrap: break-word !important;
    word-break: break-word !important;
    word-wrap: break-word !important;
    white-space: break-spaces !important;
  }

  body,
  html {
    font-family: "Times New Roman", Tinos, Times, serif !important;
  }

  .font {
    font-family: "Times New Roman", Tinos, Times, serif !important;
  }

  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
  }

  p {
    margin-top: 0;
  }

  .text-italic {
    font-style: italic;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .top-0 {
    top: 0;
  }

  .right-0 {
    right: 0;
  }

  .left-0 {
    left: 0;
  }

  .px-8 {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .pt-12 {
    padding-top: 3rem;
  }

  .p-0 {
    padding: 0;
  }

  .p-1 {
    padding: 0.25rem;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  .float-none {
    float: none;
  }

  .block {
    display: block;
  }

  .inline-block {
    display: inline-block !important;
  }

  .flex {
    display: flex;
  }

  .flex-col {
    flex-direction: column;
  }

  .align-top {
    vertical-align: top;
  }

  .text-center {
    text-align: center;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .leading-none {
    line-height: 1;
  }

  .align-middle {
    vertical-align: middle;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-end {
    justify-content: end;
  }

  .justify-between {
    justify-content: space-between;
  }

  .items-start {
    align-items: flex-start;
  }

  .items-center {
    align-items: center;
  }

  .col-4 {
    width: 33.33% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-3 {
    width: 25% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-5 {
    width: 41% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-6 {
    width: 50% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-7 {
    width: 50% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .col-12 {
    width: 100% !important;
    display: inline-block !important;
    vertical-align: top;
  }

  .w-full {
    width: 100% !important;
  }

  .w-half {
    width: 50% !important;
  }

  .w-one-third {
    width: 33.333% !important;
  }

  .w-two-thirds {
    width: 66.667% !important;
  }

  .font-bold {
    font-weight: 700;
  }

  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .text-sm {
    font-size: 0.92rem;
    line-height: 1.25rem;
  }

  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .text-base {
    font-size: 1rem;
    /* 16px */
    line-height: 1.5rem;
    /* 24px */
  }

  .pl-3 {
    padding-left: 0.75rem;
  }

  .pl-12 {
    padding-left: 3rem;
  }

  .pl-16 {
    padding-left: 4rem;
  }

  .pr-12 {
    padding-right: 3rem;
  }

  .pr-16 {
    padding-right: 4rem;
  }

  .m-6 {
    margin: 1.5rem;
  }

  .mt-2 {
    margin-top: 0.5rem;
  }

  .mt-3 {
    margin-top: 0.75rem;
  }

  .mt-4 {
    margin-top: 1rem;
  }

  .ml-6 {
    margin-left: 1.5rem;
  }

  .mt-6 {
    margin-top: 1.5rem;
  }

  .mt-8 {
    margin-top: 2rem;
  }

  .mt-10 {
    margin-top: 2.5rem;
  }

  .mt-12 {
    margin-top: 3rem;
  }

  .mt-16 {
    margin-top: 4rem;
  }

  .mt-24 {
    margin-top: 6rem;
  }

  .mt-44 {
    margin-top: 11rem;
  }

  .ml-1 {
    margin-left: 0.25rem;
  }

  .ml-2 {
    margin-left: 0.5rem;
  }

  .ml-3 {
    margin-left: 0.75rem;
  }

  .ml-4 {
    margin-left: 1rem;
  }

  .mr-1 {
    margin-right: 0.25rem;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-1 {
    margin-bottom: 0.25rem;
  }

  .mb-2 {
    margin-bottom: 0.5rem;
  }

  .mb-3 {
    margin-bottom: 0.75rem;
  }

  .mb-6 {
    margin-bottom: 1.5rem;
  }

  .mb-8 {
    margin-bottom: 2rem;
  }

  .fs-14 {
    font-size: 14px;
  }
  .fs-16 {
    font-size: 16px;
  }

  .table-width-full table {
    width: 100% !important;
    // border-collapse: collapse !important;
  }

  .hodo-table figure {
    margin: 0 0 1rem !important;
  }

  .hodo-table table {
    border-collapse: collapse !important;
    width: 100% !important;
    border: 1px solid #dbdde0 !important;
    margin-top: 1rem !important;
  }

  .hodo-table table thead th {
    text-transform: uppercase !important;
  }

  .hodo-table table th,
  .hodo-table table td {
    padding: 8px !important;
    border: 1px solid #dbdde0 !important;
  }

  .box-number {
    border: 1px solid;
    height: 1.5rem !important;
  }

  .box-item {
    font-family: "Times New Roman", Tinos, Times, serif !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
    border-right: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box-item-bhyt {
    font-family: "Times New Roman", Tinos, Times, serif !important;
    height: 0.5rem !important;
    padding: 0.5rem;
    border-right: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box-item-bhyt:last-child,
  .box-item:last-child {
    border-right: none;
  }

  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
  }
  .copy-icon {
    width: 24px !important;
    height: 24px !important;
  }
}
.card {
  cursor: pointer;
  padding: 4px 8px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  width: fit-content;
  transition: all linear 0.2s;
}
.active {
  background: #20419b;
  color: #fff;
}
</style>

